import React from 'react';
import './Product.css';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import  Carousel  from 'react-bootstrap/Carousel';
import Product1Img from '../../utils/images/products/colorCorr/coloured_corogated.png';
import Product2Img from '../../utils/images/products/corrugated/corr1.jpeg';
import Product3Img from '../../utils/images/products/idealBox/idb4.png';
import Product4Img from '../../utils/images/products/idealVersatile/idv2.png';
import Product5Img from '../../utils/images/products/romanTile/rt1.png';
// Various images for product lines
//idealBox
import idb1 from '../../utils/images/products/idealBox/idb1.png'
import idb2 from '../../utils/images/products/idealBox/idb2.png'
import idb3 from '../../utils/images/products/idealBox/idb3.png'
import idb4 from '../../utils/images/products/idealBox/idb4.png'
//ideal versatile
import idv1 from '../../utils/images/products/idealVersatile/idv1.png'
import idv2 from '../../utils/images/products/idealVersatile/idv2.png'
import idv3 from '../../utils/images/products/idealVersatile/idv3.png'
import idv4 from '../../utils/images/products/idealVersatile/idv4.png'
import idv5 from '../../utils/images/products/idealVersatile/idv5.png'
// roman tile
import rt1 from '../../utils/images/products/romanTile/rt1.png'
import rt2 from '../../utils/images/products/romanTile/rt2.png'
import rt3 from '../../utils/images/products/romanTile/rt3.png'
import rt4 from '../../utils/images/products/romanTile/rt4.png'

//products
const Products = [
    {
        id: 1,
        images: [Product1Img],
        title: 'Color Corrugated',
        description: "Popular color options for corrugated roofing include reds, greens, blues, tans and other earth tones chosen to complement a building's exterior design. The colored finish gives the roof a more vibrant, aesthetically-pleasing look compared to standard galvanized corrugated panels. Color corrugated roofing is commonly used on residential, agricultural and industrial buildings."
    },
    {
        id: 2,
        images: [Product2Img],
        title: 'Corrugated',
        description: "Upgrade to corrugated roofing - the durable, low-maintenance solution providing decades of protection. Galvanized steel panels with a wave-like profile increase strength while shedding water effortlessly. Fire-resistant, economical, and available in versatile colors. Corrugated roofing is the smart investment safeguarding your property for years to come. Experience unmatched value - choose corrugated."
    },
    {
        id: 3,
        images: [idb1,idb2,idb3,idb4],
        title: 'Ideal Box',
        description: "Durable steel/aluminum panels with rectangular ridges for incredible strength and water runoff. Corrosion-resistant and available in multiple colors. Premium metal roofing solution for long-lasting performance."
    },
    {
        id: 4,
        images: [idv2,idv3,idv4],
        title: 'Ideal Versatile',
        description: "Versatile is a premium steel tile roofing sheet with the look of a classic roof tile but with a much longer life span. It is Kenya’s longest running painted steel tile and has built a legacy of affordable beauty for thousands of product users."
    },
    {
        id: 5,
        images: [rt1,rt3,rt4],
        title: 'Roman Tile',
        description: "Elevate your home's style with Roman tile roofing sheets. Enjoy the classic beauty of traditional clay tiles without the weight and expense. Durable steel or aluminum construction delivers superior weather protection and fire resistance. Available in rich colors like terra cotta red. Achieve timeless curb appeal and lasting value with an elegant Roman tile roof."
    },
]



function Product() {
  return (
    <div className='product-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Products</h1>
                <p className='text-center w-75 mb-5'>We have a large selection of roofing options for you</p>
            </div>
        </header>

        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <div className="row g-4">
                    {Products.map((Product) => (
                        <div key={Product.id} className='col-md-6 col-lg-4'>
                            <Link to="/Product" className='text-decoration-none'>
                                <Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
                                 <Carousel wrap={true}  >   
                              {Product.images.map((image,index)=>{
                                return(
                                  
                                       <Carousel.Item key={index} interval={2000} >
                                             <Card.Img variant="top" src={image} className='h-50'  />
                                         </Carousel.Item> 
                                      
                                )
                              })}  
                              </Carousel>
                          
                                    
                                               
                                    <Card.Body className='p-md-5 d-flex flex-column align-items-center'>
                                        <Card.Title className='fs-2 mb-4'>{Product.title}</Card.Title>
                                        <Card.Text className='text-center'>{Product.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Product;