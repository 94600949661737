import React from 'react'
import './Map.css'

const Map = () => {
  return (
    <div className='map-responsive'> 
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15954.686509228462!2d36.9336206!3d-1.37371!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0dc9e6c7bda7%3A0x4fab8cbe8b7261ed!2sIDEAL%20Mabati!5e0!3m2!1sen!2ske!4v1710963304703!5m2!1sen!2ske" 
        width="600" 
        height="450"
        title='Responsive Google Map'
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade">

          </iframe>
    </div>
  )
}

export default Map