import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import AboutUsSectionImg from '../../utils/images/roofing_factory_worker_portrait.jpeg';
//import ChooseSection from '../../components/ChooseSection/ChooseSection';
import Winner1 from '../../utils/images/winners/001.png';
import Winner2 from '../../utils/images/winners/002.png';
import Winner3 from '../../utils/images/winners/003.png';
import Winner4 from '../../utils/images/winners/004.jpg';
import Winner5 from '../../utils/images/winners/005.jpg';
const winners = [
    {
        id: 1,
        img: [Winner1]
    },
    {
        id: 2,
        img: [Winner2]
    },
    {
        id: 3,
        img: [Winner3]
    },
    {
        id: 4,
        img: [Winner4]
    },
    {
        id: 5,
        img: [Winner5]
    },

];

function About() {
  return (
    <div className='about-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>About Us</h1>
                <p className='text-center w-75 mb-5'>Quality roofing solutions built on years of expertise. Protecting homes and businesses across Kenya with skill, integrity and a commitment to excellence since 2017.</p>
            </div>
        </header>

        <div className='container my-5'>
            <div className="row">
                <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                    <h2 className='mb-4 mb-lg-5'>About Us</h2>
                    <p>IDEAL Mabati supplies all types of Mabati profiles and their accessories. These includes decra(shingles & classic), romantile, versatile, eurotile, box profile, normal corrugated/aluzinc,coloured corrugated etc, of all gauges.We offer prompt and free delivery countrywide. Integrity, quality and customer satisfaction are values we hold dear.</p>
                    <p className='mb-4 mb-lg-5'>

                    We are a trusted provider of top-notch roofing services in Kenya. Established in 2017, our company has steadily built a reputation for excellence over the past 6 years. Based in Nairobi, we serve customers across the nation with our team of highly skilled and experienced roofers. From roof installations and repairs to replacements and maintenance, we handle it all with a keen eye for detail and a commitment to using only premium roofing materials. Ideal Mabati takes pride in our workmanship, delivering durable and aesthetically pleasing roofing solutions tailored to Kenya's diverse climate conditions. Whether you need a new roof for your residential or commercial property, you can count on our dedicated staff to get the job done right the first time. Experience the Ideal Mabati difference today.</p>
                    <Link to="/contact">
                        <button type='button' className='btn btn-primary btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                </div>
                <div className='col-lg-6 d-flex justify-content-center'>
                    <img src={AboutUsSectionImg} className='img-fluid w-75 rounded' alt="" />
                </div>
            </div>
        </div>

        {/* <div className='bg-dark text-light py-5'>
            <ChooseSection />
        </div> */}

        <div className='bg-body-tertiary py-5'>
            <div className="container">
                <h2 className='text-center mb-5'>Our Winners</h2>
                <div className='row g-4'>
                    {winners.map((winner) => (
                        <div key={winner.id} className='col-md-4'>
                            <img src={winner.img} className=' rounded' style={{maxWidth:'100%',height:'400px'}}alt="" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default About;