import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

function FaqAccordion() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5'>Frequently asked questions</h2>
            <p className='text-center mb-5'>Still have questions about our roofing sheets? We encourage you to explore our comprehensive FAQ section. Find detailed answers and clarity on materials, installation, maintenance and more. Get the information you need before making your decision.</p>
            <Accordion defaultActiveKey="" flush>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header>What are the benefits of metal roofing sheets?</Accordion.Header>
                    <Accordion.Body>
                    Metal roofing sheets are durable, fire-resistant, low-maintenance and long-lasting. They can withstand extreme weather while providing an attractive, modern look.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>How are metal roofing sheets installed? </Accordion.Header>
                    <Accordion.Body>
                    Roofing sheets are installed by overlapping and interlocking the panels horizontally along the roof slope using specialized fasteners and sealants.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header>What colors are available for roofing sheets?</Accordion.Header>
                    <Accordion.Body>
                    In addition to standard metallic finishes, roofing sheets come in a wide variety of baked-on color coatings like red, blue, green and more.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                    <Accordion.Header>Do metal roofing sheets require special maintenance?</Accordion.Header>
                    <Accordion.Body>
                    While very low-maintenance, metal roofs benefit from periodic inspections and cleanings to remove built-up debris and prevent premature corrosion or staining.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </div>
  )
}

export default FaqAccordion;