
import React from 'react';
import './Accessories.css';
import { Card } from 'react-bootstrap';
import bargef from '../../utils/images/accessories/bargef/old/b01.jpg';
import board from '../../utils/images/accessories/board/old/bf1.jpg';
import ridge from '../../utils/images/accessories/ridge/011.jpg';
import ridge2 from '../../utils/images/accessories/ridge2/02.jpg';
import  sidewall from '../../utils/images/accessories/sidewall/s02.jpg';
import valley from '../../utils/images/accessories/valley/09.jpg';
import valley2 from '../../utils/images/accessories/valley2/05.jpg';
import valley3 from '../../utils/images/accessories/valley3/05.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
import { Link } from 'react-router-dom';

const accessories = [
    {
        id: 1,
        img: [bargef],
        title: 'Barge Flashing 28G',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!'
    },
    {
        id: 2,
        img: [board],
        title: 'Barge Board Flashing',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!'
    },
    {
        id: 3,
        img: [ridge],
        title: 'Ridge 30 Gauge',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!'
    },
    {
        id: 4,
        img: [ridge2],
        title: 'Ridge 28 Gauge',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!'
    },
    {
        id: 5,
        img: [sidewall],
        title: 'SideWall Flashing',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!'
    },
    {
        id: 6,
        img: [valley],
        title: 'Valley 28 Gauge ',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!'
    },
    {
        id: 7,
        img: [valley2],
        title: 'Valley 2',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!'
    },
    {
        id: 8,
        img: [valley3],
        title: 'Valley 487mm',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam odio eos distinctio ducimus! Modi quaerat dolores illo accusamus maxime cupiditate culpa minus animi repellat autem!'
    },
];

function Courses() {
  return (
    <div className='accessories-page'>
        <header className='height-75'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>MABATI Accessories</h1>
                <p className='text-center w-75 mb-5'>Unlock the full potential of your roofing system with our premium line of accessories. Engineered for seamless integration and long-lasting performance.</p>
            </div>
        </header>

        <div className='container py-5'>
            <div className='row g-4'>
                {accessories.map((course) => (
                    <div key={course.id} className='col-lg-4'>
                        <Link to='/contact'>
                        <Card className='text-white shadow scale-hover-effect h-100' >
                            <Card.Img src={course.img} />
                            <Card.ImgOverlay className='d-flex flex-column align-items-center justify-content-center p-md-5'>
                                <Card.Title className='fs-1 text-primary'>{course.title}</Card.Title>
                                {/* <Card.Text className='text-center'>{course.description}</Card.Text> */}
                            </Card.ImgOverlay>
                        </Card>
                        </Link>
                    </div>
                ))}
            </div>
        </div>

        <div className='bg-dark text-light py-5'>
            <FaqAccordion />
        </div>
    </div>
  )
}

export default Courses;