import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import TakeALookImg from '../../utils/images/products/hero/hero1.png';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
import Map from '../../components/Map/Map';

// import Product1Img from '../../utils/images/products/idealVersatile/idv1.png';
// import Product2Img from '../../utils/images/accessories/bargef/b01.jpg';


// const products = [
//     {
//         id: 1,
//         img: [Product1Img],
//         title: 'Mabati',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, fugit? Doloremque deserunt ipsum eaque, dolor tempore, minima nisi debitis, et quas voluptatibus nam ex. Necessitatibus eligendi ratione expedita! Porro, ut.'
//     },
//     {
//         id: 2,
//         img: [Product2Img],
//         title: 'Accessories',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, fugit? Doloremque deserunt ipsum eaque, dolor tempore, minima nisi debitis, et quas voluptatibus nam ex. Necessitatibus eligendi ratione expedita! Porro, ut.'
//     },
 
// ];

function Home() {
  return (
    <div className='home-page'>
        <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
            <div className='container d-flex flex-column align-items-center'>
                <h2>Welcome To</h2>
                <h1 className='text-center fw-semibold'>Ideal Mabati</h1>
                <p>Your No.1 Roofing Solutions Partner!</p>
                <div className='d-flex flex-column flex-sm-row align-items-center'>
                    <Link to="/product">
                        <button type='button' className='btn btn-primary btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Products</button>
                    </Link>
                    <Link to="/contact">
                        <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                    </Link>
                </div>
            </div>
        </header>

        <div className="py-5">
            <ChooseSection />
        </div>

        <div className='py-5 bg-light'>
            <div className="container">
                <div className='row d-flex align-items-center justify-content-around'>
                    <div className='col-lg-5'>
                        <h2 className='text-capitalize'>We Have Everything!</h2>
                        <p>We encourage you to browse through and find what you need</p>
                        <Link to="/product">
                            <button type='button' className='btn btn-primary btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Take a Look</button>
                        </Link>
                    </div>
                    <div className='col-lg-5 mt-2 mt-lg-0'>
                        <img src={TakeALookImg} className='img-fluid' alt="" style={{borderRadius:'10px'}} />
                    </div>
                </div>
            </div>
        </div>

        <div className="py-5">
            <FaqAccordion />
        </div>
        <Map/>

        {/* <div className='blog-section text-light py-5'>
            <div className='container d-flex flex-column align-items-center'>
                <h2 className='text-center text-capitalize mb-5'>Latest on the product</h2>
                <div className='row g-4'>
                    {products.map((product) => (
                        <div key={product.id} className='col-md-6 col-lg-4'>
                            <Link to="/product" className='text-decoration-none'>
                                <Card className='h-100 shadow scale-hover-effect'>
                                    <Card.Img variant="top" src={product.img} />
                                    <Card.Body className='p-md-5'>
                                        <Card.Title>{product.title}</Card.Title>
                                        <Card.Text>{product.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                    ))}
                </div>
                <Link to="/product">
                    <button type='button' className='btn btn-primary btn-lg mt-5'>Read More Products</button>
                </Link>
            </div>
        </div> */}
    </div>
  )
}

export default Home;